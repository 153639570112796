import { Chip } from '@mui/material';
import styles from './chip.module.scss';

export const EvesChip = (props: any) => {
   let color: any;
   let isDisabled: boolean = false;
   let customStyle: any = {};
   switch (props.type) {
      case 'Charging': {
         color = 'primary';
         break;
      }
      case 'Available':
      case 'success': {
         color = 'success';
         break;
      }
      case 'Unavailable': {
         customStyle = {  color: '#3B3B3B' ,borderColor:'#3B3B3B'}; // Set the custom color and text color
         break;
      }
      case 'Faulted':
      case 'error':
      case 'Expired': {
         color = 'error';
         break;
      }
      case 'Preparing':
      case 'Finishing': {
         color = 'warning';
         break;
      }
      case 'Suspended_EVSE':
      case 'SuspendedEV': {
         color = 'primary';
         break;
      }
      case 'Reserved':
      case 'Occupied': {
         color = 'info';
         break;
      }
      case 'Valid': {
         color = 'success';
         break;
      }
      case 'Revoked': {
         color = 'warning';
         break;
      }
      case 'Expires soon': {
         color = 'warning';
         break;
      }
      case 'Pending': {
         color = 'warning';
         break;
      }

      // cases for billing invoice status
      case 'Paid': {
         color = 'success';
         break;
      }

      case 'Active': {
         color = 'success';
         break;
      }

      case 'Idle': {
         color = 'error';
         break;
      }

      // cases for
      case 'Finalized': {
         color = 'primary';
         break;
      }

      // inter oprability status
      case 'New': {
         color = 'info';
         break;
      }
      case 'Install': {
         color = 'muted';
         break;
      }
      default: {
         color = 'default';
         isDisabled = true;
         break;
      }
   }
   return (
      <>
         <Chip
            style={
             {
               ...customStyle,
               ...(props.maxWidthResize ? { maxWidth: props.maxWidthResize } : {})
             }
            }
            className={styles.chip}
            label={props.label}
            color={color}
            variant='outlined'
            disabled={isDisabled}
         />
      </>
   );
};

import { LookupEntityType } from '../enums/enums';
import { IContextPropsModel } from '../utils/withContext';

export class AddressFormModel {
  city?: string = '';
  country?: string = '';
  coordinates?: number[] | string[] = ['', ''];
  address1?: string = '';
  address2?: string = '';
  postalCode?: string = '';
  department?: string = '';
  region?: string = '';
}

export interface IParamModel {
  key: string;
  value: string | number | boolean | Date | null | undefined;
}

export interface ILookupPropsModel extends IContextPropsModel {
  showLookupModal: boolean;
  onModalClose: () => void;
  entityType: LookupEntityType;
  selectionMode: 'single' | 'multiple' | boolean;
  defaultSortColumn?: string;
  title: string;
  onSelect?: (data: any) => void;
  params?: IParamModel[];
  'data-cy'?: string;
  tableChildren?: any;
  showSearch?: boolean;
  preSelectedData?: any;
  isConnected?:any;
}

export interface ILookupListStateModel {
  search: string;
  limit: number;
  page: number;
  sortDescending: boolean;
  sortFields: string;
  data: Record<string, any>[];
  totalRowsCount: number;
  selectedRows: any[];
  defaultSortColumn?: string;
  projectFields: [];
}

export interface ILookupColumnConfig {
  id: string;
  label: string;
  allowSort?: boolean;
  minWidth?: number;
  sortKey?: string;
  maxWidth?: number;
  textAlign?: string;
  projectField?: string;
}

export interface columnsType {
  allowSort?: boolean;
  id: string;
  label: string;
  maxWidth?: number;
  minWidth?: number;
  projectField?: string;
  sortKey?: string;
  sortOrder?: any;
  textAlign?: string;
  visible?: boolean;
  width?: any;
  tooltip?:any;
}

export interface IDateTimeRangeModel {
  cleanable: boolean;
  defaultStartDate: any;
  defaultEndDate: any;
  startDate: any;
  endDate: any;
  isDefaultRangeShortcut?: boolean;
  onDateRangeChange: (data: any) => void;
}

export interface IConfig {
  readonly centralSystemServer: {
    readonly pollIntervalSecs: any;
  };
  readonly asset: {
    readonly maxImageKb: any;
  };
  readonly user: {
    readonly maxPictureKb: any;
    readonly captchaSiteKey: any;
  };
  readonly tenant: {
    readonly maxLogoKb: any;
  };
  readonly company: {
    readonly maxPictureKb: any;
  };
  readonly site: {
    readonly maxPictureKb: any;
  };
  readonly siteArea: {
    readonly maxPictureKb: any;
  };
  readonly advanced: {
    readonly debounceTimeSearchMillis: any;
  };
}

import { EvesCheckbox } from '@/common/components/atoms/checkbox/checkbox';
import { EvesTextbox } from '@/common/components/atoms/textbox/textbox';
import { IContextPropsModel, withContext } from '@/common/utils/withContext';
import { Edit, PinDrop } from '@mui/icons-material';
import {
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useState } from 'react';
import GeoMap from '@/common/components/organisms/geoMap/geoMap';
import LookupComponent from '@/common/components/lookupComponent';
import { Filters, LookupEntityType, TenantComponents } from '@/common/enums/enums';
import { ChargingStationFormModel } from '@/modules/chargingStation/shared/models/chargingStationForm';
import {
  chargingStationschemaConstants,
  requiredFields,
} from '@/modules/chargingStation/shared/shemas/chargingStationSchema';
import { convertWattToAmp } from '@/common/utils/withContext';
import Connector from './connector';
import { useTranslation } from 'react-i18next';
import { isActive } from '@/common/utils/utils';
import FilterSelector from '@/common/components/filterSelector';
import EvesSelect from '@/common/components/atoms/select/select';

interface IChargingStationFormPropsModel extends IContextPropsModel {
  chargingStationForm: UseFormReturn<ChargingStationFormModel, any>;
  limit?: number;
  fetchAllChargingStations: () => void;
  phaseAssignmentToGridMap?: any;
  state?: any;
  canUpdateChargingStation: boolean | undefined;
}

const ChargingStation = (props: IChargingStationFormPropsModel) => {
  const siteAreaParams = [
    { key: 'Issuer', value: true },
    { key: 'WithSite', value: true },
  ];
  const { watch } = props.chargingStationForm;
  const isPublic = watch('public');
  const chargingStationFormValues = props.chargingStationForm.getValues();
  const [showTariffId, setShowTariffId] = useState(false);
  const [showSiteAreaModal, setSiteAreaModal] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const chargingStationForm = props.chargingStationForm;
  const chargingStationId = chargingStationFormValues.id;
  const { t: translate } = useTranslation();
  const isOCPIActive = isActive(TenantComponents.OCPI);

  const onLocationPick = (latlng: any) => {
    setShowMap(false);
    props.chargingStationForm.setValue(
      'coordinates.longitude' as any,
      latlng.lng,
      { shouldValidate: true, shouldDirty: true }
    );
    props.chargingStationForm.setValue(
      'coordinates.latitude' as any,
      latlng.lat,
      { shouldValidate: true, shouldDirty: true }
    );
  };

  const handleClose = () => {
    setShowMap(false);
  };

  const onClickSiteAreaLookUpModal = () => {
    setSiteAreaModal(true);
  };

  const onCloseSiteAreaLookUpModal = () => {
    setSiteAreaModal(false);
  };
  const onMaximumPowerChange = () => {
    props.chargingStationForm.trigger('maximumPower');
    const maximumPower = watch('maximumPower');
    const power = convertWattToAmp(
      chargingStationFormValues,
      0,
      maximumPower as number
    );
    props.chargingStationForm.setValue('maximumAmps', power);
  };

  const onSiteAreaSelect = (siteArea: any) => {
    props.chargingStationForm.setValue('siteArea', siteArea[0].name, {
      shouldValidate: true,
      shouldDirty: true,
    });
    props.chargingStationForm.setValue('siteAreaID', siteArea[0].id);
    setSiteAreaModal(false);
  };

  const handleManualConfiguration = () => {
    props.dialogService?.showConfirm(
      {
        title: `${translate(
          'chargers.dialog.disable_manual_configuration.title'
        )}`,
        description: `${translate(
          'chargers.dialog.disable_manual_configuration.confirm'
        )}`,
        confirmType: 'YES_NO',
      },
      (result: any) => {
        if (result === 'YES') {
          props.fetchAllChargingStations();
        } else {
          props.chargingStationForm.setValue('manualConfiguration', true);
        }
      }
    );
  };

  if (!props.chargingStationForm.watch('siteArea')?.length) {
    document.getElementById('sitearea')?.focus();
    document.getElementById('sitearea')?.blur();
  }

  const filterOptions = [
    {
      label: 'Connected',
      value: 'Connected'
     
    },
    {
      label: 'Commissioned',
      value: 'Commissioned'
    },
    {
      label: 'Active',
      value: 'Active'
     
    },
    {
      label: 'Inactive',
      value: 'Inactive'
    },
    {
      label: 'RMA',
      value: 'RMA'
     
    }
    
  
  ];

  const getOptionsWithDisabledStatus = (options, fieldValue) => {
    return options?.map(option => {
      let isDisabled = false; // Default to not disabled
  
      switch (fieldValue) {
        case 'Connected':
          isDisabled = true; // All options disabled
          break;
  
        case 'Commissioned':
          isDisabled = option.value !== 'Active'; // Only Active should be selectable
          break;
  
        case 'Active':
          isDisabled = option.value !== 'Inactive'; // Only Inactive should be selectable
          break;
  
        case 'Inactive':
          isDisabled = option.value !== 'Active' && option.value !== 'RMA'; // Only Active and RMA should be selectable
          break;
  
        case 'RMA':
          isDisabled = true; // All options disabled
          break;
  
        default:
          isDisabled = false; // Default to not disabled
          break;
      }
  
      return {
        ...option,
        disabled: isDisabled,
      };
    });
  };
  
  return (
    <>
      {showSiteAreaModal && (
        <LookupComponent
          data-cy='chargingStationSiteAreaLookUp'
          title={`${translate('chargers.assign_site_area')}`}
          showLookupModal={showSiteAreaModal}
          onModalClose={onCloseSiteAreaLookUpModal}
          selectionMode='single'
          defaultSortColumn='name'
          entityType={LookupEntityType.SITEAREA}
          onSelect={onSiteAreaSelect}
          params={siteAreaParams}
          preSelectedData={props.chargingStationForm.getValues()}
        />
      )}

      <Grid container direction='row' spacing={2}>
        {/* NOTE: future purpose */}
        {/* {chargingStationFormValues.issuer &&<Grid item xs={6}>
					<Controller name="chargingStationURL" control={chargingStationForm.control}
						render={({ field }) => (
							<EvesTextbox {...field} id="chargingStationURL" label={translate('chargers.charger_url')} fullWidth disabled={props.state.isChargingStationURL || !props.canUpdateChargingStation} variant="standard" /> )} />
				</Grid>} */}
        <Grid item xs={6}>
          <Controller
            name='siteArea'
            control={chargingStationForm.control}
            render={({ field }) => (
              <EvesTextbox
                disabled={!props.canUpdateChargingStation}
                {...field}
                id='sitearea'
                label={translate('site_areas.title')}
                fullWidth
                onClick={
                  props.canUpdateChargingStation && onClickSiteAreaLookUpModal
                }
                variant='standard'
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position='end'
                      style={{ cursor: 'pointer' }}
                    >
                      {' '}
                      {props.canUpdateChargingStation && (
                        <Edit sx={{ color: 'black', height: '15px' }} />
                      )}
                    </InputAdornment>
                  ),
                  readOnly: true,
                }}
                required={requiredFields['siteArea']}
                error={!!chargingStationForm.formState?.errors.siteArea}
                helperText={translate(
                  chargingStationForm.formState?.errors.siteArea?.message || ''
                )}
              />
            )}
          />
        </Grid>

        <Grid item xs={3}>
          <Controller
            name='maximumPower'
            control={chargingStationForm.control}
            render={({ field }) => (
              <EvesTextbox
                {...field}
                id='maximumPower'
                label={translate('chargers.maximum_energy')}
                fullWidth
                // disabled={
                //   props.state.isMaximumPower || !props.canUpdateChargingStation
                // }
                onChange={(event: any) => {
                  field.onChange(event);
                  onMaximumPowerChange();
                }}
                required={requiredFields['maximumPower']}
                error={!!chargingStationForm.formState?.errors.maximumPower}
                helperText={translate(
                  chargingStationForm.formState?.errors.maximumPower?.message ||
                    ''
                )}
                variant='standard'
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            name='maximumAmps'
            control={chargingStationForm.control}
            render={({ field }) => (
              <EvesTextbox
                {...field}
                id='maximumAmps'
                label={translate('chargers.maximum_energy_amps')}
                fullWidth
                disabled={true}
                variant='standard'
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            name='coordinates.latitude'
            control={chargingStationForm.control}
            render={({ field }) => (
              <EvesTextbox
                disabled={!props.canUpdateChargingStation}
                {...field}
                id='latitude'
                label={translate('general.latitude')}
                fullWidth
                variant='standard'
                required={requiredFields['coordinates.latitude']}
                error={
                  !!chargingStationForm.formState?.errors.coordinates?.latitude
                }
                helperText={translate(
                  chargingStationForm.formState?.errors.coordinates?.latitude?.message?.toString() ||
                    ' '
                )}
                InputProps={{
                  endAdornment: props.canUpdateChargingStation && (
                    <InputAdornment
                      position='end'
                      style={{ cursor: 'pointer' }}
                      onClick={() => setShowMap(true)}
                    >
                      <IconButton>
                        <PinDrop fontSize='small' />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            name='coordinates.longitude'
            control={chargingStationForm.control}
            render={({ field }) => (
              <EvesTextbox
                disabled={!props.canUpdateChargingStation}
                {...field}
                id='longitude'
                label={translate('general.longitude')}
                fullWidth
                variant='standard'
                required={requiredFields['coordinates.longitude']}
                error={
                  !!chargingStationForm.formState?.errors.coordinates?.longitude
                }
                helperText={translate(
                  chargingStationForm.formState?.errors.coordinates?.longitude?.message?.toString() ||
                    ' '
                )}
                InputProps={{
                  endAdornment: props.canUpdateChargingStation && (
                    <InputAdornment
                      position='end'
                      onClick={() => setShowMap(true)}
                      style={{ cursor: 'pointer' }}
                    >
                      {' '}
                      <IconButton>
                        <PinDrop fontSize='small' />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid
                    data-cy='search-field'
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    xl={3}
                  >
               <Controller
  name='chargerStatus'
  control={chargingStationForm.control}
  render={({ field }) => (
    <EvesSelect
      label={`${translate('general.status')}`}
      options={getOptionsWithDisabledStatus(filterOptions, chargingStationFormValues?.checkStatus)}
      {...field}
      disabled={chargingStationFormValues?.checkStatus === 'Connected'}
      onChange={(event) => {
        field.onChange(event);
      }}
    />
  )}
/>

                   
                  </Grid>
        {(showTariffId || isPublic) &&
          chargingStationFormValues.issuer &&
          isOCPIActive && (
            <Grid item xs={3}>
              <Controller
                name='tariffID'
                control={chargingStationForm.control}
                render={({ field }) => (
                  <EvesTextbox
                    {...field}
                    id='tariffID'
                    label={translate('ocpi.tariff_id')}
                    fullWidth
                    disabled={false || !props.canUpdateChargingStation}
                    variant='standard'
                    error={!!chargingStationForm.formState?.errors?.tariffID}
                    helperText={`${translate(
                      chargingStationForm.formState?.errors.tariffID?.message?.toString() ||
                        ''
                    ).replace(
                      /{{length}}/g,
                      `${chargingStationschemaConstants.tariffIDMaxLength}`
                    )}`}
                  />
                )}
              />
            </Grid>
          )}
      </Grid>

      {chargingStationFormValues.issuer && (
        <Grid
          sx={{ marginTop: '10px', marginBottom: '25px' }}
          container
          direction='row'
          spacing={2}
        >
          <Grid item xs={3}>
            <Controller
              name='public'
              control={chargingStationForm.control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <EvesCheckbox
                      {...field}
                      id='public'
                      checked={props.chargingStationForm.watch('public')}
                      disabled={
                        props.state.isPublic || !props.canUpdateChargingStation
                      }
                      onChange={(event: any) => {
                        field.onChange(event);
                        if (event.target.checked) setShowTariffId(true);
                        else setShowTariffId(false);
                      }}
                    />
                  }
                  label={translate('chargers.public')}
                />
              )}
            />
          </Grid>
          {props.state.isSiteAreaSmartCharging && (
            <Grid item xs={3}>
              <Controller
                name='excludeFromSmartCharging'
                control={chargingStationForm.control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <EvesCheckbox
                        id='excludeFromSmartCharging'
                        disabled={
                          props.state.isExcludeFromSmartCharging ||
                          !props.canUpdateChargingStation
                        }
                        {...field}
                        checked={props.chargingStationForm.watch(
                          'excludeFromSmartCharging'
                        )}
                      />
                    }
                    label='Exclude from Smart Charging'
                  />
                )}
              />
            </Grid>
          )}

          {/* <Grid item xs={3}>
            <Controller
              name='forceInactive'
              control={chargingStationForm.control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <EvesCheckbox
                      id='forceInactive'
                      disabled={!props.canUpdateChargingStation}
                      {...field}
                      checked={props.chargingStationForm.watch('forceInactive')}
                    />
                  }
                  label={translate('chargers.force_inactive')}
                />
              )}
            />
          </Grid> */}
            
          <Grid item xs={3}>
            <Controller
              name='manualConfiguration'
              control={chargingStationForm.control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <EvesCheckbox
                      id='manualConfiguration'
                      disabled={!props.canUpdateChargingStation}
                      {...field}
                      checked={props.chargingStationForm.watch(
                        'manualConfiguration'
                      )}
                      onChange={(event: any) => {
                        field.onChange(event);
                        if (!event.target.checked) {
                          handleManualConfiguration();
                        }
                      }}
                    />
                  }
                  label={translate('chargers.manual_configuration')}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name='masterSlave'
              control={chargingStationForm.control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <EvesCheckbox
                      id='masterSlave'
                      {...field}
                      disabled={!props.canUpdateChargingStation || true}
                      checked={props.chargingStationForm.watch('masterSlave')}
                    />
                  }
                  label={translate('chargers.master_slave')}
                />
              )}
            />
          </Grid>
     
        </Grid>
      )}

      <Connector
        canUpdateChargingStation={props.canUpdateChargingStation}
        chargingStationForm={props.chargingStationForm}
        isPublic={isPublic}
      />
      {showMap && (
        <GeoMap
          label={`${translate('chargers.title')}`}
          name={chargingStationId}
          isOpen={showMap}
          handleClose={handleClose}
          onLocationPick={onLocationPick}
        ></GeoMap>
      )}
    </>
  );
};

export default withContext(ChargingStation);
